// Generated by Framer (999bb5e)

import { addFonts, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {BJ7z3ADbv: {hover: true}};

const serializationHash = "framer-I3I9Z"

const variantClassNames = {BJ7z3ADbv: "framer-v-1mxfdbk"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "BJ7z3ADbv", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1mxfdbk", className, classNames)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"BJ7z3ADbv"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgba(255, 255, 255, 0.5)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgb(12, 31, 240)", borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16, boxShadow: "inset 0px 0px 12px 3px rgba(255, 255, 255, 0.36), 0px 3px 16px 0px rgba(12, 31, 240, 0.35)", ...style}} variants={{"BJ7z3ADbv-hover": {boxShadow: "inset 0px 0px 12px 3px rgba(255, 255, 255, 0.36), 0px 8px 21px 0px rgba(12, 31, 240, 0.35)"}}} {...addPropertyOverrides({"BJ7z3ADbv-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO1NGIFBybyBEaXNwbGF5IEJvbGQ=", "--framer-font-family": "\"SF Pro Display Bold\", \"SF Pro Display Bold Placeholder\", \"-apple-system\", \"BlinkMacSystemFont\", sans-serif", "--framer-font-size": "18px", "--framer-line-height": "21.6px", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>WATCH FREE DEMO</motion.p></React.Fragment>} className={"framer-2lwk6h"} fonts={["CUSTOM;SF Pro Display Bold"]} layoutDependency={layoutDependency} layoutId={"Y7lJ3kkfL"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)"}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-I3I9Z.framer-qnddxl, .framer-I3I9Z .framer-qnddxl { display: block; }", ".framer-I3I9Z.framer-1mxfdbk { cursor: pointer; height: 54px; overflow: hidden; position: relative; width: 225px; will-change: var(--framer-will-change-override, transform); }", ".framer-I3I9Z .framer-2lwk6h { flex: none; height: auto; left: 32px; pointer-events: auto; position: absolute; top: 16px; white-space: pre; width: auto; }", ".framer-I3I9Z[data-border=\"true\"]::after, .framer-I3I9Z [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 54
 * @framerIntrinsicWidth 225
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"XJ6n5CugT":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerEB1brsFog: React.ComponentType<Props> = withCSS(Component, css, "framer-I3I9Z") as typeof Component;
export default FramerEB1brsFog;

FramerEB1brsFog.displayName = "butto";

FramerEB1brsFog.defaultProps = {height: 54, width: 225};

addFonts(FramerEB1brsFog, [{explicitInter: true, fonts: [{family: "SF Pro Display Bold", source: "custom", url: "https://framerusercontent.com/assets/e2xSj9IcJ8dsIvELAZDx3LfWno.woff2"}]}], {supportsExplicitInterCodegen: true})